import React from "react";
import CheckoutProvider from "context/checkoutContext";

import Main from "components/Layout/main";
import CheckoutSteps from "components/UI/Checkout/checkoutSteps";
import StaticPageMeta from "components/UI/staticPageMeta";

import "styles/checkout-page.scss";


const Checkout = (props) => {
    const gtmData = {
        page: {
            title: "Checkout",
            type: "Checkout",
        },
    };

    return (<>
        <StaticPageMeta pageName="Checkout"/>
        <Main className="checkout" gtmData={gtmData}>
            <CheckoutProvider>
                <div className="checkout__wrapper">
                    <CheckoutSteps/>
                </div>
            </CheckoutProvider>
        </Main>
    </>);
};

export default Checkout;
